<template>
  <b-container fluid class="p-0">
    <b-row v-for="(item, index) in items" :key="getID(index)" class="form-repeater">
      <b-col cols="4">
        <b-form-input
          v-if="!dropZone"
          size="sm"
          placeholder="Key"
          v-model="items[index].key"
        />

        <custom-input
          v-else
          label="Key"
          :key="uuidv4() + versionKey"
          v-model="items[index].register_destiny"
          horizontal
          noChanger
          :possibleValues="possibleValues"
          disableSourceRecommend
        />
        
      </b-col>
      <span class="mr-2 ml-2 align-self-center mb-2">: </span>
      <b-col cols="6">
        <b-form-input
          v-if="!dropZone"
          size="sm"
          placeholder="Value"
          v-model="items[index].value"
        />

        <custom-input
          label="Value"
          v-else
          :key="uuidv4()"
          v-model="items[index].register_1"
          horizontal
          :possibleValues="possibleValues"
          disableSourceRecommend
          @blur="checkFileFlag(index)"
        >
        <template #label-right v-if="isFormDataBody">
          <div class="d-flex">
            is file:
            <b-form-checkbox class="ml-25" @change="formatIsFile(index)" v-model="isFileIndicator[index]" :unchecked-value="undefined"/>
          </div>
          
        </template>
      </custom-input>
      </b-col>
      <b-col cols="1" class="text-right align-self-center mb-2">
        <b-button
          size="sm"
          variant="outline-danger"
          @click="deleteItem(index)"
        >
          <feather-icon
            icon="XIcon"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button :variant="!button ? 'none' : 'outline-primary'" class="button-add px-75 py-50" @click="addItem">{{ addButtonText }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { 
  BContainer, 
  BRow, 
  BCol, 
  BButton, 
  BFormInput, 
  BInputGroup, 
  BFormCheckbox
} from 'bootstrap-vue'
import CustomInput from '../CustomInput.vue'
import { v4 as uuidv4 } from 'uuid'
import HelperTooltip from '@/layouts/components/HelperTooltip.vue';
import NormalizedAgent from '@/custom/class/NormalizedAgent.js';

  export default {
    components: {
      BContainer, 
      BRow, 
      BCol, 
      BButton, 
      BFormInput, 
      BInputGroup,
      CustomInput,
      HelperTooltip,
      BFormCheckbox
    },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      addButtonText: {
        type: String,
        default: '+ add new' 
      },
      dropZone: {
        type: Boolean,
        default: false,
      },
      possibleValues: {
        type: Object,
        default: undefined
      },
      defaultValue: {
        type: [ NormalizedAgent, Object ],
        required: true
      },
      button: {
        type: Boolean,
        default: false,
      },
      isFormDataBody:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        uuidMap: {},
        uuidv4,
        versionKey: 0,
        FILE_INDICATAOR_FLAG: 'FIQON_FILE@',
        isFileIndicator: {}
      }
    },
    computed: {
      items: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    },
    mounted () {
      let idx = 0
      this.items.forEach(itm => {
        this.checkFileFlag(idx)
        idx = idx + 1
      });
    },  
    methods: {
      addItem() {
        this.items.push(structuredClone(this.defaultValue));
      },
      deleteItem(key) {
        if (this.items[key].id) {
          this.$emit('deleteItem', { "item": this.items[key], "index": key });
        }
        this.items.splice(key, 1);
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        } 
        const uuid = uuidv4();
        this.uuidMap[key] = uuid;
        return uuid;
      },
      formatIsFile(index){  
        if (this.isFileIndicator[index] && !this.items[index].register_1.value.startsWith(this.FILE_INDICATAOR_FLAG)){
          this.items[index].register_1.value = this.FILE_INDICATAOR_FLAG + this.items[index].register_1.value
        } else if (this.items[index].register_1.value.startsWith(this.FILE_INDICATAOR_FLAG)){
          this.items[index].register_1.value = this.items[index].register_1.value.substring(this.FILE_INDICATAOR_FLAG.length)
        }
        this.versionKey = this.versionKey + 1
      },
      checkFileFlag(index){
        let FLAG = this.FILE_INDICATAOR_FLAG
        if (this.items[index].register_1.value && this.items[index].register_1.value.startsWith(FLAG)){
          this.$set(this.isFileIndicator, index, true)
        } else {
          this.$set(this.isFileIndicator, index, undefined)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.form-repeater{
  transition: all 0.5s ease-in-out;
}
.button-add:hover {
  color: #12e066;
}
</style>